import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Head from "../components/head"
import Header from "../components/header"
import Footer from "../components/footer"
import { LoginHeader } from "../components/nav";
import { SocialHeader } from "../components/social";

export function Banner_img() {
  return <StaticImage src="../../static/images/banner2.jpg" alt="banner" />
}
export default () => (
  <>
    <Header titleText="Our Services - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <LoginHeader />
        <SocialHeader />
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a className="current_menu" rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
      </div>
      <Head />

      <div className="banner banner_solutions">
        <Banner_img />
        <div className="banner_text">
          <div className="bannerinfo">
            ALL THE TOOLS YOU NEED
            <br />
            TO GROW AND SERVE YOUR CUSTOMERS
          </div>
          <ul className="loginbox">
            <li>
              <a
                rel=""
                href="https://calendly.com/kate-hao_happy-mango/happy-mango-30-minute-online-demo"
                className="banks-get-a-demo"
              >
                Demo
              </a>
            </li>
            <li>
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                className="banks-try-for-free"
              >
                Trial
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="media_list">
        <ul>
          <li>
            <a rel="" href="/media/">
              <img
                alt="americanbanker"
                src="/sites/default/files/americanbanker1.jpg"
              />{" "}
            </a>
          </li>
          <li>
            <a rel="" href="/media/">
              <img alt="usatoday" src="/sites/default/files/usatoday.jpg" />{" "}
            </a>
          </li>
          <li>
            <a rel="" href="/media/">
              <img
                alt="credit-union-times"
                src="/sites/default/files/credit-union-times1.jpg"
              />{" "}
            </a>
          </li>
        </ul>
      </div>

      <div className="why_div">
        <div id="Digitalize" className="div_height"></div>
        <div className="Digitalize why_item">
          <div className="why_top se">
            <h1>Front to Back Digitalization</h1>
            <div className="content">
              Plug-and-play solution for a wide range of products and services
            </div>
          </div>
          <ul className="why_list">
            <img
              alt="DigitalizeFinancialServices"
              src="/images/DigitalizeFinancialServices.png"
            />
            <li className="li_son">
              <h1>Financial Services</h1>
              <ul>
                <li>
                  <span></span>Fraud detection
                </li>
                <li>
                  <span></span>Online closing
                </li>
                <li>
                  <span></span>New account opening
                </li>
                <li>
                  <span></span>Automatic ACH
                </li>
                <li>
                  <span></span>Customized underwriting
                </li>
                <li>
                  <span></span>Electronic documents
                </li>
              </ul>
            </li>
            <li className="li_son">
              <h1>Financial Products</h1>
              <ul>
                <li>
                  <span></span>Credit Builder Loans
                </li>
                <li>
                  <span></span>Mortgage Loans
                </li>
                <li>
                  <span></span>Small Dollar Loans
                </li>
                <li>
                  <span></span>Small Business Loans
                </li>
                <li>
                  <span></span>Car Loans
                </li>
                <li>
                  <span></span>Credit Cards
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="Customize" className="div_height"></div>
        <div className="Customize why_item">
          <div className="why_top se">
            <h1>Customized Underwriting</h1>
            <div className="content">
              Select and define risk metrics to fit every risk appetite
            </div>
          </div>
          <ul className="why_list">
            <img
              alt="CustomizeUnderwriting"
              src="/images/CustomizeUnderwriting.png"
            />
            <li className="li_son">
              <h1>Data-Driven Models</h1>
              <ul>
                <li>
                  <span></span>Cash flow projection
                </li>
                <li>
                  <span></span>Financial account aggregation
                </li>
                <li>
                  <span></span>Income spending analysis
                </li>
                <li>
                  <span></span>Affordability assessment
                </li>
              </ul>
            </li>
            <li className="li_son">
              <h1>Diverse Metrics</h1>
              <ul>
                <li>
                  <span></span>Credit reports
                </li>
                <li>
                  <span></span>Cash flows
                </li>
                <li>
                  <span></span>Financial behavior
                </li>
                <li>
                  <span></span>Personal reference
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="Expand" className="div_height"></div>
        <div className="Expand why_item">
          <div className="why_top se">
            <h1>Online Marketing Tools</h1>
            <div className="content">
              Online marketing and collaboration tools
            </div>
          </div>
          <ul className="why_list">
            <img
              alt="ExpandCustomerBase"
              src="/images/ExpandCustomerBase.png"
            />
            <li className="li_son">
              <h1>Referral Network</h1>
              <ul>
                <li>
                  <span></span>Community organizations
                </li>
                <li>
                  <span></span>Employer partnerships
                </li>
                <li>
                  <span></span>Seamless collaboration
                </li>
                <li>
                  <span></span>Referral tracking and analysis
                </li>
              </ul>
            </li>
            <li className="li_son">
              <h1>Marketing Campaigns</h1>
              <ul>
                <li>
                  <span></span>Digital coupon codes
                </li>
                <li>
                  <span></span>Dynamic landing pages
                </li>
                <li>
                  <span></span>Effectiveness analysis
                </li>
                <li>
                  <span></span>Demographic reporting
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="Improve" className="div_height"></div>
        <div className="Improve why_item">
          <div className="why_top se">
            <h1>Financial Health Management</h1>
            <div className="content">
              Accommodate short-term customer needs while delivering long-term
              results
            </div>
          </div>
          <ul className="why_list">
            <img
              alt="DeepenCustomerRelations"
              src="/images/DeepenCustomerRelations.png"
            />
            <li className="li_son">
              <h1>Unique Features</h1>
              <ul>
                <li>
                  <span></span>Alternative credit analysis
                </li>
                <li>
                  <span></span>Automated Smart ACH<b>(R)</b>
                </li>
                <li>
                  <span></span>Same-day funding
                </li>
                <li>
                  <span></span>Free PFM tools
                </li>
              </ul>
            </li>
            <li className="li_son">
              <h1>Financial Coaching</h1>
              <ul>
                <li>
                  <span></span>Forecasting and budgeting tools
                </li>
                <li>
                  <span></span>Personal financial coach
                </li>
                <li>
                  <span></span>Comprehensive financial picture
                </li>
                <li>
                  <span></span>Automated tips and alerts
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div id="Track" className="div_height"></div>
        <div className="Track why_item">
          <div className="why_top se">
            <h1>Impact Analysis and Reporting</h1>
            <div className="content">
              Accurately and objectively measure your impact and performance
            </div>
          </div>
          <ul className="why_list">
            <img
              alt="TrackProgramImpact"
              src="/images/TrackProgramImpact.png"
            />
            <li className="li_son">
              <h1>Data Driven Analysis</h1>
              <ul>
                <li>
                  <span></span>Before and after comparison
                </li>
                <li>
                  <span></span>Segmentation analysis
                </li>
                <li>
                  <span></span>Portfolio analysis
                </li>
                <li>
                  <span></span>Credit, asset, cash flow metrics
                </li>
              </ul>
            </li>
            <li className="li_son">
              <h1>Comprehensive Reporting</h1>
              <ul>
                <li>
                  <span></span>Real time management reports
                </li>
                <li>
                  <span></span>Downloadable data analytics
                </li>
                <li>
                  <span></span>Impact analysis and tracking
                </li>
                <li>
                  <span></span>Metro II reporting
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
    <script src="/js/script.js"></script>
  </>
)
